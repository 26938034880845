// NOTE: for local debugging change to '/api/v1'
export const API_PATH = '/io/inforeq/api/v1';
export const shortRequestURISegment = 'inforeq';
export const shortResponseURISegment = 'infores';
export const shortWebRequestURISegment = 'inforeq';
export const PERMISSIONS = {
    INFOREQ_READ: `${shortRequestURISegment}:read`,
    INFORES_READ: `${shortResponseURISegment}:read`,
    INFORES_CREATE: `${shortResponseURISegment}:create`,
    INFORES_MODIFY: `${shortResponseURISegment}:modify`,
    INFORES_DELETE: `${shortResponseURISegment}:delete`,
    PROJECT_READ: `project:read`,
    PROJECT_SHARE: `project:share`,
};
